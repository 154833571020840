<template>
  <v-app :class="siteMode === 'UH' ? 'login-app-uh-marketing' : 'login-app'">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" class="d-flex justify-left align-left">
          <div
            :class="
              $mq === 'mobile'
                ? 'd-flex justify-center align-end'
                : 'd-flex justify-left align-end'
            "
          >
            <v-img
              :src="siteMode === 'UH' ? logoUH : logoVP"
              class="mt-3"
              contain
              :width="siteMode === 'UH' ? 150 : 150"
            />
            <h1
              :class="
                siteMode === 'UH'
                  ? 'display-2 white--text font-weight-bold'
                  : 'display-2 white--text font-weight-bold'
              "
            >
              {{ siteMode === 'UH' ? 'VPExam' : 'VPExam' }}
            </h1>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          :class="
            $mq === 'mobile'
              ? 'd-flex justify-center align-end'
              : 'd-flex justify-end align-end'
          "
        >
          <div
            :class="
              $mq === 'mobile'
                ? 'd-flex justify-center align-end'
                : 'd-flex justify-end align-end'
            "
          >
            <h3
              :class="
                siteMode === 'UH'
                  ? ' white--text font-weight-bold'
                  : ' white--text font-weight-bold'
              "
            >
              Already have an account?
              <span
                @click="$router.push('/login')"
                style="text-decoration: underline; cursor: pointer"
                >Sign In</span
              >
            </h3>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="siteMode === 'UH'" cols="12" md="6">
          <v-card class="reflect" height="100%">
            <v-card-text style="height: 100%">
              <v-img
                height="100%"
                src="@/assets/UHHeartImage.jpg"
                cover
              ></v-img>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="siteMode !== 'UH'" cols="6" md="3">
          <v-card class="reflect" height="100%">
            <v-card-text style="height: 100%">
              <v-img height="100%" src="@/assets/IMG_8966 2.jpeg" cover></v-img>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="siteMode !== 'UH'" cols="6" md="3">
          <v-card class="reflect" height="100%">
            <v-card-text style="height: 100%">
              <v-img
                height="100%"
                src="@/assets/ClinicJugular.jpg"
                cover
              ></v-img>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-card
            height="100%"
            class="d-flex reflect flex-column justify-space-between"
          >
            <v-card-text class="black--text text-md-h5 text-center">
              Send your Physician critical medical information and Schedule your
              appointment.
            </v-card-text>
            <v-card-text
              class="d-flex flex-column justify-center align-center black--text text-md-h5 text-center"
            >
              <div>
                HIPAA compliantly send your:
              </div>

              <div>
                -Vitals, History, Review of symptoms, Medical Documents,
                Medications <br />
                -Share Apple Health and Google Fit data including Smart watch
                and bluetooth device information
                <br />
                -Arrange for full Physical Exam with VPExam Express and VPExam
                Partners
              </div>
            </v-card-text>
            <v-card-text>
              <v-btn color="primary" to="/patientregistration" x-large block
                >Register Now</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div
        :style="$mq === 'mobile' ? 'margin-top: 80px' : 'margin-top: 80px'"
        :class="
          $mq === 'mobile'
            ? ''
            : 'd-flex flex-column justify-center align-center'
        "
        style="position: relative"
      >
        <h1 class="display-2 m-title text-center">Download VPExam App</h1>
        <v-row v-if="$mq === 'mobile'">
          <v-col cols="6">
            <v-img src="@/assets/appstore.svg" height="84px" contain> </v-img>
          </v-col>
          <v-col cols="6" md="3">
            <v-img
              src="@/assets/google-play-badge.png"
              height="84px"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              src="@/assets/App Overview.png"
              class="mt-3"
              contain
              max-width="100%"
              width="100%"
            ></v-img>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="3">
            <a
              href="https://apps.apple.com/us/app/vpexam-telehealth-app/id1561980292"
              target="_blank"
            >
              <v-img
                src="@/assets/appstore.svg"
                height="84px"
                contain
                class="appstore"
              >
              </v-img>
            </a>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              src="@/assets/App Overview.png"
              class="mt-3"
              contain
              max-width="100%"
              width="100%"
            ></v-img>
          </v-col>
          <v-col cols="12" md="3">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.mgenio.vpexam"
            >
              <v-img
                src="@/assets/google-play-badge.png"
                class="playstore"
                height="84px"
                contain
              ></v-img>
            </a>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-row
      :style="$mq === 'mobile' ? 'margin-top: -150px' : 'margin-top: -200px;'"
      :class="
        $mq === 'mobile'
          ? 'd-flex flex-column justify-center align-center'
          : 'd-flex flex-column justify-center align-center'
      "
    >
      <h1 class="display-2 m-title text-center">Why VPExam?</h1>
      <iframe
        class="video"
        style="margin: 40px 0;"
        width="1166"
        height="656"
        src="https://www.youtube.com/embed/tKfUBajNiIA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </v-row>
    <v-footer color="white" padless>
      <v-col class="text-center" cols="12">
        <img
          src="@/assets/health.png"
          style="height: 70px; padding-top: 10px;"
          alt=""
        />
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
import logoUH from '@/assets/logo-white.png'
import logoVP from '@/assets/logo-white.png'
export default {
  name: 'homepage',
  data() {
    return {
      logoUH: logoUH,
      logoVP: logoVP,
      siteMode: process.env.VUE_APP_SITE
    }
  },
  created() {
    this.$emit(`update:layout`, PublicLayout)
  }
}
</script>

<style scoped>
.reflect {
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(85%, transparent), to(rgba(250, 250, 250, 0.1)));
}
.m-title {
  color: #fff;
  font-weight: 900;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #005879;
  letter-spacing: 2px !important;
}
.appstore {
  margin-top: 129px;
}
.playstore {
  margin-top: 129px;
}

@media only screen and (max-width: 756px) {
  .video {
    width: auto;
    height: auto;
  }
}
</style>
